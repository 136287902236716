import React, { Component } from "react";
// import {Table, Button} from 'react-bootstrap';
import { Table, SecondaryButton, InputLabel } from '@ttd/lingo';


class BucketsList extends Component {
  constructor() {
    super();
    this.state = {
      filteredInfo: {}
    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleSortFilter = this.handleSortFilter.bind(this);
  }

  handlePagination(page) {
    dispatch(PaginateData(page));
  }

  handleSortFilter(params) {
    dispatch(SortFilterData(params));
  }

  render() {
    let buckets_list = this.props.buckets_list;
    let CountData = buckets_list.length;
    let project_filters = [];
    let bucket_filters = [];

    if (buckets_list !== null && buckets_list.length > 0) {
      buckets_list.forEach(element => { project_filters.push({ 'text': element["Project"], 'value': element["Project"] }); });
      buckets_list.forEach(element => { bucket_filters.push({ 'text': element["Bucket"], 'value': element["Bucket"] }); });
    }




    return (
      <div>

        <Table
          pagination={false}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          className="bucket_list"
          columns={[
            {
              dataIndex: 'Project',
              title: 'Project',
              sorter: (a, b) => a["Project"].localeCompare(b["Project"]),
              // onFilter: (value, record) => record.Project.startsWith(value),
              // filters: project_filters,
              // filterSearch: true,
            },
            {
              dataIndex: 'Bucket',
              title: 'Bucket',
              sorter: (a, b) => a["Bucket"].localeCompare(b["Bucket"]),
              // onFilter: (value, record) => record.Bucket.startsWith(value),
              // filters: bucket_filters,
              // filterSearch: true,
            },

            {
              title: 'Folders',
              render: (bucket) => <div className="folders">{bucket.Folders.map(b =>
                <div key={b.PathPrefix}><p><InputLabel isRequired>{b.DisplayName} ({bucket.Bucket} -&gt; {b.PathPrefix})</InputLabel><span>{b.Users.map(u => u.Email).join(", ")}</span></p></div>)}</div>,
            },

            {
              title: 'Actions',
              render: (bucket) => <div>
                <SecondaryButton
                  as="button"
                  className="btn-space"
                  onClick={() => this.props.editBucket(bucket)}>
                  Edit
                </SecondaryButton>
              </div>
            },
          ]}
          data-ttd-inspect-id="bucket_list_table"
          dataSource={buckets_list}
          rowKey="Project"
        />
      </div>
    );
  }
}

export default BucketsList;
