import React, { Component } from "react";
import {Button, Modal} from 'react-bootstrap';
import { delete_user } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";

import { PrimaryButton, SecondaryButton } from '@ttd/lingo';


class DeleteUser extends Component {
  constructor() {
    super();
    this.state = {};
    this.confirm_delete = this.confirm_delete.bind(this);
  }

  confirm_delete(){
    let self = this;
    let email = this.props.selected_path;
    let token = get_token();


    delete_user(token, email)
      .then((res)=>{
        self.props.reload_users();})
      .catch(function (error2) {
        console.log("Something went wrong with deleting user");
        console.log(error2);
      });
  }

  render() {
    let self = this;

    return (
      <Modal show={this.props.show_delete} onHide={this.props.hide_delete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Delete "<strong>{this.props.selected_path}</strong>"?</p>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            as="button"
            onClick={this.props.hide_delete}>Close</SecondaryButton>

          <PrimaryButton
            as="button"
            onClick={()=>self.confirm_delete()}>Confirm delete</PrimaryButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteUser;