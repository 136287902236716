import React from "react";

import {Routes, Route } from 'react-router-dom';

import FileWizard from '../components/upload_wizard/FileWizard';
import UsersWizzard from '../components/users/UsersWizzard';
import BucketsWizzard from "../components/buckets/BucketsWizzard";
import Navigation from '../layouts/Navigation';
import MainLayout from "../layouts/MainLayout";

import { useAuth } from "react-oidc-context";

export default function Router() {
  const auth = useAuth();

  return (
    <div>
      <Routes>
        <Route path="/" exact element={<><header><Navigation User={auth.user?.profile.sub} /></header><div className="container-fluid p-b-20"><FileWizard /></div></>} />
        <Route path="/loginCallback" element={<MainLayout><FileWizard /></MainLayout>} />
        <Route path="/users" exact element={<><header><Navigation User={auth.user?.profile.sub} /></header><div className="container-fluid p-b-20"><UsersWizzard /></div></>} />
        <Route path="/buckets" exact element={<><header><Navigation User={auth.user?.profile.sub} /></header><div className="container-fluid p-b-20"><BucketsWizzard /></div></>} />
      </Routes>
    </div>
  );
}