import React, { Component, useState, useEffect, useRef } from 'react';

import { Stepper, Step } from "react-form-stepper";
import { Container, Row, Col, Fade } from 'react-bootstrap';
import { get_paths } from "../../services/apiCallerService/ApiCaller";
import PathSelector from "./Step1_PathSelector";
import FileUploader from "./Step2_FileUploader";
import Confirmation from "./Step3_Confirmation";
import UploadError from "./Step4_UploadError";
import { CLIENT_ID, USER_POOL_ID, AWS_REGION, TOKEN } from "../../configs/oidc_constants";

import { get_s3_signed_url, upload_file_to_aws } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";

import { PrimaryButton, SecondaryButton, ErrorBannerMessage } from '@ttd/lingo';


export default function FileWizard() {


  const [selectedFile, setSelectedFile] = React.useState(null);
  const [paths, setPaths] = React.useState(null);
  const [selectedPath, setSelectedPath] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [uploading, setUploading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState("PathSelector");
  const [error, setError] = React.useState(0);



  const wizzard = useRef(null);


  const reset = () => {
    window.location.reload(false);
  }

  const next_step = () => {
    switch (activeStep) {
      case "PathSelector":
        setActiveStep("FileUploader");
        break;
      case "FileUploader":
        setActiveStep("Confirmation");
        break;
      default:
        break;
    }
  }

  const show_error = (error_message) => {
    setUploading(false);
    setError(error_message);
    setActiveStep("Error");
  }

  const set_step_value = (path) => {
    setSelectedPath(path);
  }


  const set_selected_file_for_upload = (file) => {
    setSelectedFile(file);
  }



  const upload_file = (path_setting, selectedFileName) => {
    let token = JSON.parse(localStorage.getItem(TOKEN));

    let username = token["profile"]["email"];

    path_setting["Filename"] = selectedFileName;
    path_setting["User"] = username;

    get_s3_signed_url(token, path_setting)
      .then(signed_url_res => {
        let signed_url = signed_url_res["URL"];
        let success = signed_url_res["Success"];
        if (success === false) {
          show_error(signed_url_res["StatusCode"]);
        }

        if (signed_url !== "")
          upload_file_to_aws(signed_url, selectedFile, username)
            .then(res => {
              if (res === 200) {
                if (success === true) {
                  setUploading(false);
                  setActiveStep("Confirmation");
                }
                else
                  show_error(res);
              }
              else {
                show_error(res);
              }
            })
            .catch(function (error2) {
              console.log("Something went wrong with uploading file");
              console.log(error2);
            });

      })
      .catch(function (error) {
        console.log("Something went wrong with signed url");
        console.log(error);

        show_error(error);
      });
  }



  useEffect(() => {
    if (uploading === true) {
      upload_file(selectedPath, selectedFile.name);
    }

  }, [uploading])




  useEffect(() => {
    if (paths === null) {
      const token = get_token();

      get_paths(token)
        .then(res => {
          if (res !== null && "Permissions" in res && res["Permissions"].length === 0) {
            setError("Your access has not been setup correctly. Please contact your technical account manager.")
            setShowError(true);
          }
          else {
            setPaths(res["Permissions"]);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("Something went wrong with signed url");
          console.log(error);
        });

    }
  });


  return (
    <div>

      <Container>
        <Row>
          <Col>
            <Stepper activeStep={activeStep}>
              <Step label="Select Destination" />
              <Step label="Upload File" />
              <Step label="Confirmation" />
            </Stepper>
          </Col>
        </Row>
        <Row>
          <Col>
            {showError &&
              <ErrorBannerMessage>
                {error}
              </ErrorBannerMessage>
            }

            {activeStep == "PathSelector" &&
              <div>
                <PathSelector
                  stepName={"PathSelector"}
                  on_path_change={(path) => set_step_value(path)}
                  selectedPath={selectedPath}
                  loading={loading}
                  path_list={paths} />

                <div className="btn-group-wizzard">
                  <SecondaryButton
                    as="button"
                    data-ttd-inspect-id="back-primary-button"
                    className="btn-space"
                    onClick={(e) => reset()}>
                    Back
                  </SecondaryButton>

                  <PrimaryButton
                    as="button"
                    data-ttd-inspect-id="next-step-primary-button"
                    className="btn-space"
                    onClick={(e) => next_step()}
                    isDisabled={selectedPath == null}>
                    Next
                  </PrimaryButton>
                </div>
              </div>
            }
            {activeStep == "FileUploader" &&
              <div>
                <FileUploader
                  stepName={"FileUploader"}
                  selectedPath={selectedPath}
                  set_selected_file={(file) => set_selected_file_for_upload(file)}
                  uploading={uploading}
                  show_error={(error) => setError(error, () => { set_step_value("ShowError") })} />

                <div className="btn-group-wizzard">
                  <SecondaryButton
                    as="button"
                    data-ttd-inspect-id="back-primary-button"
                    className="btn-space"
                    onClick={(e) => reset()}
                    isDisabled={uploading === true}>
                    Back
                  </SecondaryButton>

                  <PrimaryButton
                    as="button"
                    data-ttd-inspect-id="next-step-primary-button"
                    className="btn-space"
                    onClick={() => setUploading(true)}
                    isDisabled={selectedFile === null || uploading === true}>
                    Upload
                  </PrimaryButton>
                </div>
              </div>
            }

            {activeStep == "Confirmation" &&
              <div>
                <Confirmation
                  stepName={"Confirmation"} />
                <div className="btn-group-wizzard">
                  <SecondaryButton
                    as="button"
                    data-ttd-inspect-id="back-primary-button"
                    className="btn-space"
                    onClick={(e) => reset()}>
                    Back
                  </SecondaryButton>
                </div>
              </div>
            }


            {activeStep == "Error" &&
              <div>
                <UploadError
                  stepName={"Error"}
                  error={error} />

                <SecondaryButton
                  as="button"
                  data-ttd-inspect-id="back-primary-button"
                  className="btn-space"
                  onClick={(e) => reset()}>
                  Back
                </SecondaryButton>
              </div>
            }
          </Col>
        </Row>

      </Container>
    </div>
  );
}


// // Similar to componentDidMount and componentDidUpdate:
