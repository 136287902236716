import React, { Component } from "react";
// import {Table, Button} from 'react-bootstrap';
import { Table, SecondaryButton } from '@ttd/lingo';

import DeleteUser from './DeleteUser';

class UsersList extends Component {
  constructor() {
    super();
    this.state = {
      show_delete: false,
      path_to_delete: null,
    };
    this.delete_user = this.delete_user.bind(this);
    this.close_delete_and_reload_users = this.close_delete_and_reload_users.bind(this);
    this.hide_delete = this.hide_delete.bind(this);
    this.delete_component = React.createRef();


    this.onTableChange = this.onTableChange.bind(this);
  }

  close_delete_and_reload_users() {
    this.setState({ show_delete: false, path_to_delete: null }, () => {
      this.props.reload_users();
    });
  }

  hide_delete() {
    this.setState({ show_delete: false, path_to_delete: null });
  }

  delete_user(path) {
    this.setState({ show_delete: true, path_to_delete: path });
  }


  onTableChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  };

  render() {
    let self = this;
    let users_list = this.props.users_list;
    let user_email_filters = [];

    this.props.email_list.forEach(element => {
      user_email_filters.push({'text': element, 'value': element})
    });



    return (
      <div>
        <DeleteUser
          selected_path={this.state.path_to_delete}
          show_delete={this.state.show_delete}
          close_delete_and_reload_users={this.close_delete_and_reload_users}
          hide_delete={this.hide_delete}
          reload_users={this.props.reload_users}
          ref={this.delete_component} />

        <Table
          onChange={this.onTableChange}
          columns={[
            {
              dataIndex: 'User',
              title: 'User',
              filterSearch: true,
              filters: user_email_filters,
              onFilter: (value, record) => record.User.startsWith(value),
              sorter:  (a, b) => a["User"].localeCompare(b["User"]),
            },
            {
              dataIndex: "PermissionsStringArray",
              title: "Permissions",
              render: text => <ul>{text.map(function (permission, index2) {
                return (
                  <li key={index2}>{permission}</li>)
                })}
                </ul>
            },
            {
              dataIndex: 'User',
              title: 'Actions',
              render: (user) => <div>
                  <SecondaryButton
                    as="button"
                    className="btn-space"
                    onClick={()=>self.props.edit_user(user)}>
                    Edit
                  </SecondaryButton>&nbsp;
                  <SecondaryButton
                    as="button"
                    className="btn-space"
                    onClick={()=>self.delete_user(user)}>
                    Delete
                  </SecondaryButton>


              </div>
            },
          ]}
          data-ttd-inspect-id="user_list_table"
          dataSource={users_list}
          rowStyle={{
            height: 'standard'
          }}
          rowKey="User"
          pagination={{
            pageSizeOptions: [5, 25, 50, 100],
            pageSize: 5
          }}
        />
      </div>
    );
  }
}

export default UsersList;
