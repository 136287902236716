import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import './global.css'

import { AuthProvider } from "react-oidc-context"
import { OidcConfig } from "./configs/oidc.js"


import { lingoStrings } from './lingoStrings.js';
import {
  LingoGlobalStyles,
  LingoProvider,
} from '@ttd/lingo/lib/components/LingoProvider';

import { rootThemeCss } from '@ttd/theme';


const rootNode = document.querySelector('#root');

createRoot(rootNode).render(

  <AuthProvider {...OidcConfig}>
    <LingoProvider
      appName='clientFileUpload'
      isDev={process.env.NODE_ENV === 'development'}
      localizationSettings={lingoStrings}
      rootNode={rootNode}
      styles={[...rootThemeCss, ...LingoGlobalStyles]}>
      <App />
    </LingoProvider>
  </AuthProvider>
  ,
)
