// We don't currently have any lcoalization support in archipelago.
export const lingoStrings = {
    locale: 'en-us',
    strings: {
        all: 'All',
        ascend: 'ascend',
        chooseFile: 'Choose file',
        clearSearch: 'Clear Search',
        descend: 'descend',
        error: 'Error',
        errorMaxFileCount: (maxFileCount) =>
            `You can only import at most ${maxFileCount} ${
                maxFileCount === 1 ? 'file' : 'files'
            }.`,
        errorMaxFileSize: (formattedMaxFileSize) =>
            `You can only import files at most ${formattedMaxFileSize}.`,
        fileStatusError: 'File Error',
        fileStatusProcessing: 'File Processing',
        fileStatusSuccess: 'File Success',
        fileTypeRejection: 'You can only import files with valid file type.',
        helpInfo: 'Help Info',
        information: 'Information',
        items: 'Items:',
        itemsSelected: (count) =>
            `${count} ${count === 1 ? 'item' : 'items'} selected`,
        loading: 'Loading',
        nextPage: 'next page',
        noMatches: 'There are no matches',
        noResult: '0 results',
        optional: 'Optional',
        previousPage: 'previous page',
        removeFile: 'Remove file',
        retry: 'Retry',
        search: 'Search',
        selectAll: 'Select all',
        selectAllWithValue: (total) => `Select all (${total})`,
        selectDropdownItem: 'Select a Dropdown Item',
        selectPage: 'select page',
        selectPageSize: 'select page size',
        showingCount: (text, count) => `${text} (${count})`,
        showingOf: (start, end, total) =>
            `Showing ${start}-${end} of ${total}`,
        success: 'Success',
        toastNotificationRegion: 'Toast Notification Region',
        warning: 'Warning',
    },
};
