import React, { Component } from "react";
import {Button, ButtonGroup, Spinner} from 'react-bootstrap';

import UsersList from "./UsersList";
import AddUser from "./AddUser";

import { get_users } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";
import EditUser from "./EditUser";

import { withAuth } from "react-oidc-context";
import { PrimaryButton, Loader } from '@ttd/lingo';

class UsersWizzard extends Component {
  constructor() {
    super();
    this.state = {
      user_paths: [],
      email_list: [],
      buckets: [],
      original_buckets: [],
      step: "user_list",
      loading_users: false,
      user_to_edit: null,
      path_to_delete: null
    };

    this.switch_view = this.switch_view.bind(this);
    this.reload_users = this.reload_users.bind(this);
    this.edit_user = this.edit_user.bind(this);
  }


reload_users(){
  let self = this;
  let token = get_token();

  this.setState({loading_users: true}, ()=>{
    get_users(token)
      .then(res=>{
        let results = res;
        let buckets = JSON.stringify(results.buckets);
        let buckets_json = JSON.parse(buckets);

        let user_paths = JSON.stringify(results.user_paths);
        let user_paths_json = JSON.parse(user_paths);

        user_paths_json.sort((a, b) => a.User.localeCompare(b.User));
        let email_list = user_paths_json.map(a => a.User.toLowerCase());
        email_list.sort();


        let bucket_key = 0;


        // buckets_json.forEach(element => {
        //   element["key"]=bucket_key;
        //   let element_string = element["DisplayName"] +  " (" + element["Bucket"] + "->" + element["PathPrefix"] + ")";
        //   element["PathString"] = element_string;
        //   bucket_key++;
        // });



        user_paths_json.forEach(element => {
          let perm_string = [];
          element["Permissions"].forEach(perm => {
            perm_string.push(perm.DisplayName +  " (" + perm.Bucket + "->" + perm.PathPrefix + ")");
          });
          element["PermissionsStringArray"] = perm_string;
        });


        const original_buckets = {...buckets};


        self.setState({user_paths: user_paths_json, buckets: buckets_json, original_buckets: buckets_json, loading_users: false, email_list: email_list});
      })
      .catch(function (error) {
        console.log("Something went wrong with user list!");
        console.log(error);
      });
  });
}


componentDidMount() {
  if (this.state.user_paths.length === 0) {
    this.reload_users();
  }
}

switch_view(caller_view) {
  let self = this;

  this.setState({step: "user_list"},()=>{
    self.reload_users();
  });

}

  edit_user(user_path) {
    let users_list = this.state.user_paths;
    let user1 = users_list.filter((user) => user["User"]==user_path);
    this.setState({step: "edit_user", user_to_edit: user1[0]});
  }

  render() {
    let buckets = this.state.buckets;

    let user_paths = this.state.user_paths["user_paths"];
    // console.log(user_paths);

    return (
      <div className="home">
        {this.state.loading_users ?
          <div>
            <Loader />
          </div>:
          <div>
            <ButtonGroup className="btn-group-wizzard">
              {/* {this.state.step === "user_list" &&
                <Button
                  className="btn-space"
                  variant="outline-primary"
                  disabled={true}
                  onClick={()=>this.setState({step: "create_user"})}>Create User</Button>} */}

              {this.state.step === "create_user" && <Button className="btn-space" variant="outline-primary" onClick={()=>this.setState({step: "user_list"})}>x</Button>}
            </ButtonGroup>
            {this.state.step === "create_user" && <AddUser buckets={buckets} user_paths={user_paths} switch_view={this.switch_view} />}

            {this.state.step === "edit_user" &&
              this.state.user_to_edit !==null &&
              <EditUser
                buckets={buckets}
                user_paths={user_paths}
                switch_view={this.switch_view}
                user_to_edit={this.state.user_to_edit} />}

            {this.state.step === "user_list" &&
              <UsersList
                users_list={this.state.user_paths}
                reload_users={this.reload_users}
                edit_user={this.edit_user}
                email_list={this.state.email_list} />}
          </div>
        }
      </div>
    );
  }
}
export default withAuth(UsersWizzard);