import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import { PrimaryButton, SecondaryButton } from '@ttd/lingo';

import { useAuth, hasAuthParams } from "react-oidc-context";
import { is_user_admin, is_user_in_dev_group } from "../services/tools/SessionTools";


const Navigation = (props) => {

  const is_admin = is_user_admin();
  const is_dev = is_user_in_dev_group();

  const auth = useAuth();

  return (
    <Navbar className="ttd_nav" expand="lg">
      <Container>
        <Navbar.Brand href="/" variant="primary" >
          File Upload Tool
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {is_admin && <Nav.Link href="/users">Users</Nav.Link>}
            {is_admin && <Nav.Link href="/buckets">Buckets</Nav.Link>}
          </Nav>
          <Nav.Link>{auth.user?.profile?.email}&nbsp;&nbsp;</Nav.Link>
          <Nav>
            {is_admin && <SecondaryButton
              as="button"
              data-ttd-inspect-id="back-primary-button"
              className="btn-space"
              onClick={() => { auth.removeUser(); }}>
              Sign Out
            </SecondaryButton>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;