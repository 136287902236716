import React, { Component } from "react";

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { JsonEditor } from 'json-edit-react'

import { save_bucket } from "../../services/apiCallerService/ApiCaller";
import { bucketSchema, bucketUiSchema, AddButton, RemoveButton } from "./BucketSchema";
import { FormattedMessage } from 'react-intl';


import { PrimaryButton, SecondaryButton, Loader, ErrorBannerMessage } from '@ttd/lingo';
import { IconCircleAdd24x24 } from '@ttd/icons';



class AddBucket extends Component {
  constructor() {
    super();
    this.state = {
    };

    this.form = React.createRef();
    this.submit_form = this.submit_form.bind(this);
  }


  submit_form({ formData }, e) {
    e.preventDefault();
    this.props.save_new_bucket(formData);
  }




  render() {
    return (
      <div>
        <h1>Onboard New Project</h1>
        {this.state.saving &&
          <div>
            <Loader />
            <h3>Saving...</h3>
          </div>
        }

        {this.props.show_error &&
          <ErrorBannerMessage>
            {this.props.error_message}
          </ErrorBannerMessage>
        }

        <Form
          disabled={this.state.saving}
          schema={bucketSchema}
          uiSchema={bucketUiSchema}
          ref={this.form}
          validator={validator}
          onSubmit={this.submit_form}
          templates={{ ButtonTemplates: { AddButton, RemoveButton } }} />
      </div>
    );
  }
}

export default AddBucket;
