import React, { Component } from "react";

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { JsonEditor } from 'json-edit-react';

import { save_bucket } from "../../services/apiCallerService/ApiCaller";
import { bucketSchema, bucketUiSchema, AddButton, RemoveButton } from "./BucketSchema";
import { Loader, ErrorBannerMessage } from '@ttd/lingo';


class EditBucket extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      formState: {},
      saving: false
    };

    this.form = React.createRef();
    this.submit_form = this.submit_form.bind(this);
  }

  submit_form({ formData }, e) {
    this.setState({ saving: true }, () => {
      this.props.update_existing_bucket(formData);
    })

  }

  render() {
    return (
      <div>
        <h1>Edit Project</h1>
        {this.state.saving &&
          <div>
            <Loader />
            <h3>Saving...</h3>
            </div>
          }

          {this.props.show_error &&
          <ErrorBannerMessage>
            {this.props.error_message}
          </ErrorBannerMessage>
          }
          <Form
            disabled={this.state.saving}
            schema={bucketSchema}
            uiSchema={bucketUiSchema}
            formData={this.props.bucket}
            ref={this.form}
            validator={validator}
            onSubmit={this.submit_form}
            templates={{ ButtonTemplates: { AddButton, RemoveButton } }} />

      </div>
    );
  }
}

export default EditBucket;
