import React, { Component, useState, useEffect, useRef } from 'react';

import {Form, Button, Spinner, ButtonGroup} from 'react-bootstrap';

import {SecondaryButton, RadioGroup, Radio, InputLabel, Loader} from '@ttd/lingo';






export default function PathSelector({loading, path_list, on_path_change}) {


  const path_changed=(path)=>{
    on_path_change(path);
  }
  // useEffect(() => {
  //   if (selectedPath != {}) {
  //     console.info('useEffect selectedPath:' + selectedPath);
  //     on_path_change(selectedPath);
  //   }
  // }, [selectedPath])

  const form = useRef();

  return (
    <div>
    {loading &&
      <div>
        <Loader />
      </div>
      }
      {!(loading) &&
        <Form ref={form}>
        <RadioGroup
          onChange={(path)=>path_changed(path)}>
            {path_list != null && path_list.length>0 &&path_list.map(function (path, index) {
              return (
                <Radio value={path} key={index}>
                  {path["DisplayName"]===""? path["Bucket"] + "/" + path["PathPrefix"] : path["DisplayName"] + " (" + path["Bucket"] + "/" + path["PathPrefix"]+ ")"}
                </Radio>)
              })
            }
          </RadioGroup>
        </Form>
      }
      </div>
      );
}