import React, { Component } from "react";
import { ButtonGroup, Button, Alert } from 'react-bootstrap';


function reset(test){
  console.log("UploadError pressed reset!");
  console.log(test);
}

export default function UploadError({error, reset}) {

    return (
          <div>
            <Alert variant="danger">
              {error===401 &&
                <span>Please make sure you are on TTD VPN! If you are already, please contact administrator.</span>}

              {error===502 &&
                <span>Cannot overwrite existing file.</span>}

              {(error !==502 && error !==401) &&
                <span>Something went wrong uploading the file! Please refresh the page to try again or contact administrator.</span>}
            </Alert>
          </div>
    );
}
