import React, { Component } from "react";
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';

import { get_buckets } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";
import { withAuth } from "react-oidc-context";
import BucketsList from "./BucketsList";
import AddBucket from "./AddBucket";
import EditBucket from "./EditBucket";

import { PrimaryButton, Loader } from '@ttd/lingo';
import { save_bucket, update_bucket } from "../../services/apiCallerService/ApiCaller";

class BucketsWizzard extends Component {
  constructor() {
    super();
    this.state = {
      buckets: [],
      loading_buckets: false,
      step: "bucket_list",
      highlight: "",
      edit_bucket: {},
      show_error: false,
      error_message: "",
      hightlight: ""
    };

    this.reload_buckets = this.reload_buckets.bind(this);
    this.editBucket = this.editBucket.bind(this);
    this.save_new_bucket = this.save_new_bucket.bind(this);
    this.update_existing_bucket = this.update_existing_bucket.bind(this);
  }

  editBucket(bucket) {
    this.setState({ edit_bucket: bucket, step: "edit_bucket" })
  }

  save_new_bucket(bucket) {
    let token = get_token();
    save_bucket(token, bucket).then(bucket_result=>{

      console.log("save_new_bucket bucket_result: ", bucket_result);
      if(bucket_result.data["success"]===true){
        this.setState({hightlight: bucket["Project"], step: "bucket_list"},()=>{this.reload_buckets();});
      }
      else{
        console.log("Something went wrong creating bucket.");
        this.setState({show_error: true, error_message: "Something went wrong creating bucket."});
      }
    });
  }

  update_existing_bucket(bucket) {
    let token = get_token();
    update_bucket(token, bucket).then(bucket_result=>{
      console.log("update_existing_bucket bucket_result: ", bucket_result);
      if(bucket_result.data["success"]===true){
        this.setState({"hightlight": bucket["Project"], step: "bucket_list"}, ()=>{this.reload_buckets();});
      }
      else{
        this.setState({show_error: true, error_message: "Something went wrong updating bucket."});
      }
    });
  }

  reload_buckets() {
    let self = this;
    let token = get_token();

    this.setState({ loading_buckets: true }, () => {
      get_buckets(token)
        .then(res => {
          let results = res;
          let buckets = JSON.stringify(results.buckets);
          let buckets_json = JSON.parse(buckets);
          self.setState({ buckets: buckets_json, loading_buckets: false, step: "bucket_list" });
        })
        .catch(function (error) {
          console.log("Something went wrong with buckets list!");
          console.log(error);
        });
    });
  }


  componentDidMount() {
    if (this.state.buckets.length === 0) {
      this.reload_buckets();
    }
  }

  render() {
    let buckets = this.state.buckets;

    return (
      <div className="home">
        {this.state.loading_buckets ?
          <Loader />
          :
          <div>
            {this.state.step === "add_bucket" &&
              <AddBucket
                save_new_bucket={this.save_new_bucket}
                show_error={this.state.show_error}
                error_message={this.state.error_message} />}
            {this.state.step === "edit_bucket" &&
              <EditBucket
                bucket={this.state.edit_bucket}
                update_existing_bucket={this.update_existing_bucket}
                show_error={this.state.show_error}
                error_message={this.state.error_message} />}
            {this.state.step === "bucket_list" &&
              <div>
                <PrimaryButton
                  as="button"
                  data-ttd-inspect-id="back-primary-button"
                  className="btn-space"
                  onClick={(e) => this.setState({ step: "add_bucket" })}>
                  Add Bucket
                </PrimaryButton>
                <br />
                <BucketsList
                  buckets_list={buckets}
                  editBucket={this.editBucket} />

              </div>
            }
          </div>
        }
      </div>
    );
  }
}
export default withAuth(BucketsWizzard);