
import { CLIENT_ID, USER_POOL_ID, AWS_REGION, SIGN_IN_URI } from "./oidc_constants";
import {WebStorageStateStore} from "oidc-client";

 const OidcConfig = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: "https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID,
    client_id: CLIENT_ID,
    redirect_uri: SIGN_IN_URI,
    response_type: 'code',
    scope: 'openid profile email'
  };
  export { OidcConfig };