import React, { Component } from "react";
import { ButtonGroup, Button } from 'react-bootstrap';

function reset(test){
  console.log("Confirmation pressed reset!");
  console.log(test);
}

export default function Confirmation({error, reset}) {

    return (
          <div>
            <h1>File uploaded successfully!</h1>
          </div>
    );
}
