import React, { useRef } from "react";
import { Form } from 'react-bootstrap';
import { Loader } from '@ttd/lingo';

export default function FileUploader({ set_selected_file, uploading }) {
  const [step, setStep] = React.useState({});


  const set_selected_file_function = (selected_file) => {
    set_selected_file(selected_file);
  }

  const form = useRef();
  return (
    <div>
      <Form ref={form}>
        <Form.Group className={"wizrd_step"}>
          <Form.Label>
            <strong>Upload an Excel file</strong>
          </Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => set_selected_file_function(e.target.files[0])}
            disabled={step === "target"} />
        </Form.Group>

      </Form>
      <div>
        {uploading &&
          <Loader />
        }
      </div>
    </div>
  );
}
