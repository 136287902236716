import './App.css'
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import { useAuth, hasAuthParams } from "react-oidc-context";
// import './assets/bootstrap.min.css';




function App() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);


    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }


    if (auth.isLoading) {
        return <div>Signing you in/out...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }
    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }
    return (
        <BrowserRouter>
            <Router>
            </Router>
        </BrowserRouter>);
}

export default App;