import { CLIENT_ID, USER_POOL_ID, AWS_REGION, ADMIN_GROUP_NAME, DEV_GROUP_NAME } from "../../configs/oidc_constants";


export function get_token() {
  let token_id = "oidc.user:https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID + ":" + CLIENT_ID;
  let token = JSON.parse(localStorage.getItem(token_id));
  return token;
}

export function get_user() {
  let token = get_token();
  let username = "";
  if(token !== null)
    username = token["profile"]["email"];
  return  username;
}

export function is_user_in_admin_group(){
  let is_user_in_admin_group_flag = false;
  let token = get_token();
  if(token !== null)
    is_user_in_admin_group_flag = token["profile"]["cognito:groups"].includes(ADMIN_GROUP_NAME);

  return  is_user_in_admin_group_flag;
}


export function is_user_in_dev_group(){
  let is_user_in_dev_group_flag = false;
  let token = get_token();
  if(token !== null)
    is_user_in_dev_group_flag = token["profile"]["cognito:groups"].includes(DEV_GROUP_NAME);

  return  is_user_in_dev_group_flag;
}

export function is_user_admin() {
  let user = get_user();
  if(user !== null){
    let is_user_ttd_flag = user.includes("@thetradedesk.com");
    let is_user_in_admin_group_flag = is_user_in_admin_group();

    if(is_user_ttd_flag===true && is_user_in_admin_group_flag===true)
      return true;
  }
  return false;
}