
import { IconChartBarGraphDataInsights24x24, IconActivityLogFilled } from '@ttd/icons';
import { PrimaryButton, SecondaryButton, Loader } from '@ttd/lingo';


const bucketSchema = {
    "title": "File Upload Instance Config",
    "type": "object",
    "properties": {
        "Bucket": {
            "type": "string"
        },
        "Project": {
            "type": "string"
        },
        "Region": {
            "type": "string",
            "enum": ["ap-southeast-1", "ap-southeast-2", "eu-west-2", "us-west-1", "us-west-2", "us-east-1", "us-east-2"],
        },
        "Folders": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {

                    "DisplayName": {
                        "type": "string"
                    },
                    "PathPrefix": {
                        "type": "string"
                    },
                    "OverwriteExistingFiles": {
                        "type": "boolean",
                        "default": true
                    },
                    "Users": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "properties": {
                                "Email": {
                                    "type": "string"
                                },
                                "Permission": {
                                    "type": "string",
                                    "enum": ["upload", "view", "full"],
                                    "default": "full"
                                },
                            },
                            "required": ["Email", "Permission"]
                        }
                    }
                }
            },
            "required": ["DisplayName", "PathPrefix", "Users"]
        },
        "Admins": {
            "type": "array",
            "items": {
                "type": "string"
            }
        }
    },
    "required": ["Bucket", "Region", "Project", "Folders"]
};

const bucketUiSchema = {
    "ui:submitButtonOptions": {
        "props": {
            "disabled": false,
            "className": "btn-space clientfileupload-4lr65x",
        },
        "submitText": "Save Bucket"
    },
    Bucket: {
        // 'ui:classNames': 'red_field',
    },
    Folders: {
        "ui:title": "Folders in your bucket",
        'ui:classNames': 'folders_title',
        'ui:options': {
            orderable: false,
        },
        items: {
            Users: {
                "ui:title": "Users in your bucket",
                items: {
                    'ui:classNames': 'users_list_row',
                    'ui:options': {
                        label: false,
                        orderable: false
                    },
                    Email: {
                        'ui:options': {
                        label: false,
                        orderable: false,
                        removeable: false
                    },
                    },
                    Permission: {
                        "ui:title": "Perms",
                        'ui:widget': 'hidden'
                    }
                }
            }
        }
    },
    Admins: {
        'ui:widget': 'hidden'
    }
};


const newBucketData = {
    "Bucket": "",
    "Project": "",
    "Region": "eu-west-2",
    "Folders": [
        {
            "DisplayName": "",
            "PathPrefix": "",
            "Users": [
                {
                    "Email": "",
                    "Permission": ""
                }
            ]
        }
    ],
    "Admins": []
}


function AddButton(props) {
  const { icon, iconType, ...btnProps } = props;
  return (
    <button {...btnProps}>
      {icon} <PrimaryButton
          as="button"
          data-ttd-inspect-id="back-primary-button" >
          Add
        </PrimaryButton>
    </button>
  );
}


function RemoveButton(props) {
  const { icon, iconType, ...btnProps } = props;
  return (
    <button {...btnProps}>
      {icon} <SecondaryButton
          as="button"
          data-ttd-inspect-id="back-primary-button" >
          Remove
        </SecondaryButton>
    </button>
  );
}



export { bucketSchema, bucketUiSchema, newBucketData, AddButton,  RemoveButton};